<template>
    <div id="app">
        <picture-input
                ref="pictureInput"
                @change="onChange"
                name="fotografia"
                :prefill="picture"
                width="600"
                height="600"
                margin="16"
                accept="image/jpeg,image/png"
                size="10"
                radius="50"
                :removable="true"
                :customStrings="{
        upload: '<h1>Bummer!</h1>',
        drag: 'Arrastrar o clic 😺 ',
        change: 'Cambiar',
        remove: 'Quitar',
        fileSize: 'El tamaño del archivo excede el límite',
      }">
        </picture-input>
        <br>
        <!--<a href="https://github.com/alessiomaffeis/vue-picture-input" class="btn btn-success">Guardar Imagen</a>-->
        <!--<button type="submit" class="btn btn-success btn-block">Guardar Fotografía</button>-->
    </div>
</template>

<script>
    import PictureInput from 'vue-picture-input'

    export default {
        name: 'vue-picture',
        props: ['picture'],
        data () {
            return {
            }
        },
        components: {
            PictureInput
        },
        methods: {
            onChange () {
                console.log('New picture selected!')
                if (this.$refs.pictureInput.image) {
                    console.log('Picture loaded.');
                    const fileInput = document.querySelector( 'input[name="fotografia"]' );
                    const formData = new FormData();
                    formData.append( 'fotografia', fileInput.files[0] );
                    axios.post( 'perfil/fotografia', formData )
                        .then( ( response ) => {

                        } )
                        .catch( ( error ) => {
                            this.message = error.error;
                        } );
                } else {
                    console.log('FileReader API not supported: use the <form>, Luke!')
                }
            }
        }
    }
</script>

<style scoped>
    #app {
        text-align: center;
        color: #2c3e50;
        margin-top: 0;
    }

    h1, h2 {
        font-weight: normal;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        /* color: #42b983; */
    }
</style>
