/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

//import VueDataScooper from "vue-data-scooper";
//Vue.use(VueDataScooper);

Vue.component('vue-picture-input', require('./components/VuePictureInput.vue').default);
Vue.component('vue-chosen', require('./components/vue-chosen.vue').default);
//Vue.component('gallery', require('./components/VueGallery.vue').default);

Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('VueTagsInput', require('@johmun/vue-tags-input').default);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

/*
const app = new Vue({
    el: '#app',
    mounted:function () {
        console.log('Hola');
    }
});

*/




import VueProgressBar from 'vue-progressbar'

Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)',
    failedColor: 'red',
    height: '2px',
    thickness: '5px',
})

/**
 * Código para nuestra aplicación en el frontend
 **/

$(function(){

    $('[data-toggle="tooltip"]').tooltip();

    /*----------------------------------------------------------*/
    /*RESPONSIVE NAVIGATION JS */
    /*----------------------------------------------------------*/
    var $menu_select = $("<select />");
    $("<option />", {"selected": "selected", "value": "", "text": "Menú"}).appendTo($menu_select);
    $menu_select.appendTo("#main-navigation");
    $("#main-navigation ul li a").each(function(){
        var menu_url = $(this).attr("href");
        var menu_text = $(this).text();
        if ($(this).parents("li").length == 2) { menu_text = '- ' + menu_text; }
        if ($(this).parents("li").length == 3) { menu_text = "-- " + menu_text; }
        if ($(this).parents("li").length > 3) { menu_text = "--- " + menu_text; }
        $("<option />", {"value": menu_url, "text": menu_text}).appendTo($menu_select)
    });

    var field_id = "#main-navigation select";

    $(field_id).on('change', function(e){
        var value = e.target.value;
        window.location = value;
    });

    /*----------------------------------------------------------*/
    /*MAIN NAVIGATION DROPDOWN JS */
    /*----------------------------------------------------------*/

    // jQuery('ul.main-menu').superfish({
    //   delay:       100,                            // one second delay on mouseout
    //   animation:   {opacity:'show',height:'show'},  // fade-in and slide-down animation
    //   speed:       'fast',                          // faster animation speed
    //   autoArrows:  false                           // disable generation of arrow mark-up
    // });

    /*----------------------------------------------------------*/
    /*LAYER SLIDER JS */
    /*----------------------------------------------------------*/
    if ( $( '#layerslider' ).length && jQuery() ) {
        $('#layerslider').layerSlider({
            width : '100%',
            height : '350px',
            responsive : true,
            responsiveUnder : 940,
            sublayerContainer : 1020,
            autoStart : true,
            pauseOnHover : true,
            firstLayer : 1,
            animateFirstLayer : true,
            randomSlideshow : false,
            twoWaySlideshow : true,
            loops : 0,
            forceLoopNum : true,
            autoPlayVideos : false,
            autoPauseSlideshow : 'auto',
            keybNav : true,
            touchNav : true,
            navButtons: false,
            navStartStop: false,
            skin : 'fullwidth',
            skinsPath : 'img/layer-slider/skins/',
        });
    }

    // Inicializar las DatePiker
    if (jQuery().datepicker) {
        $('.date-picker').datepicker({
            //format: 'dd/mm/yyyy',
            //orientation: "left",
            language: 'es',
            autoclose: true
        });
        //$('body').removeClass("modal-open"); // fix bug when inline picker is used in modal
    }

    // Departamentos y Municipios
    // https://www.youtube.com/watch?v=FY6Pmrmz0Ws
    $('#departamento').on('change',function(e){
        console.log(e);
        var depto_id = e.target.value;
        //console.log(depto_id);
        //ajax
        $.get('/ajax-municipio/'+ depto_id, function(data){
            //success data
            //console.log(data);
            var municipio =  $('#municipio').empty();

            municipio.append('<option value="">Seleccione</option>');

            $.each(data,function(index,municipioObj){

                municipio.append('<option value ="'+municipioObj.id+'">'+municipioObj.nombre+'</option>');

            });
        });
    });

    // Licencia de conducir
    // Si el usuario no tiene licencia, los demás tipos de licencia se deseleccionan
    // $("input[name='licencia[]']").on( "click", function(e) {

    //     console.log(this);

    //     if( $( this ).val() === 'No tengo') {
    //         $("input[name='licencia[]']").not(this).prop( "checked", false );
    //     } else {
    //         $("input[value='No tengo']").prop( "checked", false );
    //     }
    // });

    // Desactivar ocultar el periodo final, si el usuario esta cursando sus estudios
    $('#situacion').on('change', function(e){

        if( $( this ).val() === 'Activo' ){
            $('#periodo_final_mes').hide();
            $('#periodo_final_ano').hide();
            $('#periodo_final_label').hide();
            $('#actualidad').show();
        } else {
            $('#periodo_final_mes').show();
            $('#periodo_final_ano').show();
            $('#periodo_final_label').show();
            $('#actualidad').hide();
        }
    });

    if ( $('#situacion').val() == 'Activo' ) {
        $('#periodo_final_mes').hide();
        $('#periodo_final_ano').hide();
        $('#periodo_final_label').hide();
        $('#actualidad').show();
    }

    // Si el usuario es pasante universitario, desactivar en #situacion el item concluido
    $('#tipo_estudio').on('change', function(e) {
        if( $( this ).val() == 2 ){
            $("select option[value='Concluido']").attr("disabled", "disabled");
            $('#situacion').prop("selectedIndex",0);
        } else {
            $("select option[value='Concluido']").prop("disabled", false);
        }

        /*$("#tipo_estudio, select option").each(function () {
            var $thisOption = $(this);
            var valueToCompare = 2;

            if ($thisOption.val() == valueToCompare) {
                $thisOption.attr("disabled", "disabled");
            }
        });*/
    });

    if ( $('#tipo_estudio').val() == 2 ) {
        $("select option[value='Concluido']").attr("disabled", "disabled");
        //$('#situacion').prop("selectedIndex",0);
    }

    // Nivel de estudios y carreras
    // https://www.youtube.com/watch?v=FY6Pmrmz0Ws
    $('#tipo_estudio').on('change',function(e){
        console.log(e);
        var tipo_estudio_id = e.target.value;
        //console.log(tipo_estudio_id);
        //ajax
        $.get('/ajax-carrera/'+ tipo_estudio_id, function(data){
            //success data
            //console.log(data);
            var municipio =  $('#carrera').empty();

            municipio.append('<option value="">Seleccione</option>');

            $.each(data,function(index,municipioObj){

                municipio.append('<option value="'+municipioObj.id+'">'+municipioObj.nombre+'</option>');

            });
        });
    });

    // Desactivar ocultar el periodo final, si el usuario actualmente esta laborando
    $('#experienciaActualidad').on('change', function(e){

        if( $( this ).is(':checked') ){
            $('#periodo_final_mes').hide();
            $('#periodo_final_ano').hide();
            $('#experienciaActualidad').parent().removeClass('col-md-offset-4');
        } else {
            $('#periodo_final_mes').show();
            $('#periodo_final_ano').show();
            $('#experienciaActualidad').parent().addClass('col-md-offset-4');
        }
    });

    //console.log($('#pais').val());
    //  Currículum Datos Generales
    if( $('#pais').val() == 11 ){
        $('#departamento_municipio').show();
    } else {
        $('#departamento_municipio').hide();
    }

    //
    $('#pais').on('change', function(e){

        if( $( this ).val() == 11 ){
            $('#departamento_municipio').show();
        } else {
            $('#departamento_municipio').hide();
        }
    });



    // Colocar línea verde si existen elementos en el peril: formación, idiomas, experiencia, capacitaciones, referencias
    if ( $('div.tabla').length ) {
        $('.portlet.box.green').css("border", "1px solid #5cd1db");
    }


});
