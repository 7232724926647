var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      attrs: {
        "data-placeholder": _vm.placeholder,
        multiple: _vm.multiple,
        disabled: _vm.disabled
      }
    },
    _vm._l(_vm.localOptions, function(option) {
      return _c("option", { domProps: { value: option[_vm.trackBy] } }, [
        _vm._v("\n        " + _vm._s(option[_vm.label]) + "\n    ")
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }