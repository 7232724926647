var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("picture-input", {
        ref: "pictureInput",
        attrs: {
          name: "fotografia",
          prefill: _vm.picture,
          width: "600",
          height: "600",
          margin: "16",
          accept: "image/jpeg,image/png",
          size: "10",
          radius: "50",
          removable: true,
          customStrings: {
            upload: "<h1>Bummer!</h1>",
            drag: "Arrastrar o clic 😺 ",
            change: "Cambiar",
            remove: "Quitar",
            fileSize: "El tamaño del archivo excede el límite"
          }
        },
        on: { change: _vm.onChange }
      }),
      _vm._v(" "),
      _c("br")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }